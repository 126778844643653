import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"CopyAccount HideOnMovil d-flex justify-space-between align-center"},[_c(VMenu,{attrs:{"left":"","z-index":"3000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!_vm.disabled)?_c(VBtn,_vm._g(_vm._b({staticClass:"btn",attrs:{"text":"","rounded":""}},'v-btn',attrs,false),on),[_c('div',{staticClass:"ButtonText"},[_vm._v("Copy Account")]),_c('div',{staticClass:"icon"},[_c(VIcon,[_vm._v(" mdi-content-copy ")])],1)]):_vm._e()]}}])},[_c('div',[_c(VList,[_c('div',{},_vm._l((_vm.CopyOptions),function(item,index){return _c(VBtn,{key:index,staticClass:"button d-flex justify-start align-center",attrs:{"text":""},on:{"click":function($event){return _vm.createCopyAccount(_vm.copySection)}}},[_vm._v(" "+_vm._s(item.content)+" ")])}),1)])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }